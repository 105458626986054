import { Component } from "react";
import { withTranslation } from "react-i18next";
import "./address.css";

const addressline_0_l1 = "188 Steeles Ave East"
const addressline_0_l2 = "Markham ON, L3T 1A5"
const addressline_1_l1 = "3497 Kennedy Rd Unit 3";
const addressline_1_l2 = "Scarborough, ON M1V 4S3";
const addressline_2_l1 = "59 Bramalea Rd";
const addressline_2_l2 = "Brampton, ON L6T 2W4";

class Address extends Component {
    render() {
        const { t } = this.props;
        return (
            <ul>
                <li className="address">
                    <b>{t("main.aboutus.first_office")}</b>: {addressline_0_l1}, {addressline_0_l2}
                </li>
                <li className="address">
                    <b>{t("main.aboutus.second_office")}</b>:  {addressline_1_l1}, {addressline_1_l2}
                </li>
                <li className="address">
                    <b>{t("main.aboutus.third_office")}</b>:  {addressline_2_l1}, {addressline_2_l2}
                </li>
            </ul>
        );
    }
}

export class Address0 extends Component {
    render() {
        return (
            <a
                href="https://goo.gl/maps/RMfCpJBj2JesoTSZA"
                title={addressline_0_l1 + ", " + addressline_0_l2}
            >
                {addressline_0_l1}, {addressline_0_l2}
            </a>
        );
    }
}
export class Address1 extends Component {
    render() {
        return (
            <a
                href="https://maps.app.goo.gl/iSskCq7tz14zVnbu9"
                title={addressline_1_l1 + ", " + addressline_1_l2}
            >
                {addressline_1_l1}, {addressline_1_l2}
            </a>
        );
    }
}
export class Address2 extends Component {
    render() {
        return (
            <a
                href="https://maps.app.goo.gl/yt3hxq1tSCie3E6y7"
                title={addressline_2_l1 + ", " + addressline_2_l2}
            >
                {addressline_2_l1}, {addressline_2_l2}
            </a>
        );
    }
}

export default withTranslation()(Address);