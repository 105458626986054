import "open-iconic/font/css/open-iconic-bootstrap.min.css";
import "./App.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/header/header";
import Main from "./components/main/main";
import Plumbing from "./components/services/plumbing/plumbing";
import DrainSewer from "./components/services/drainsewer/drainsewer";
import Water from "./components/services/water/water";
import WaterProof from "./components/services/waterproof/waterproof";
import WaterDamage from "./components/services/waterdamage/waterdamage";
import PastJobs from "./components/services/pastjobs/pastjobs";
import ScrollButton from "./components/scrollbutton/scrollbutton";
import Footer from "./components/footer/footer";

const renderPaths = (paths, Element) =>
  paths.map((path) => <Route key={path} path={path} element={Element} />);

function App() {
  return (
    <div className="App">
      <Header />
      <Router>
        <Routes>
          {renderPaths(["/", "/index.htm", "/index.html"], <Main />)}
          {renderPaths(["/plumbing", "/plumbing.htm", "/plumbing.html"], <Plumbing />)}
          {renderPaths(["/drainsewer", "/drainsewer.htm", "/drainsewer.html"], <DrainSewer />)}
          {renderPaths(["/water", "/water.htm", "/water.html"], <Water />)}
          {renderPaths(["/waterproof", "/waterproof.htm", "/waterproof.html"], <WaterProof />)}
          {renderPaths(["/waterdamage", "/waterdamage.htm", "/waterdamage.html"], <WaterDamage />)}
          {renderPaths(["/pastjobs", "/pastjobs.htm", "/pastjobs.html"], <PastJobs />)}
        </Routes>
      </Router>
      <ScrollButton />
      <Footer />
    </div>
  );
}

export default App;
